<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
    />
  </van-popup>
</template>
<script>
import { dateFormat } from "@/utils/tool";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({
        currentDate: new Date("2015-01-01"),
        minDate: new Date("2000-01-01"),
        maxDate: new Date()
      })
    }
  },
  data() {
    return {};
  },
  watch: {
    // currentDate(v) {
    // }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    },

    currentDate() {
      return this.config.currentDate || new Date("2015-01-01");
    },
    minDate() {
      return this.config.minDate || new Date("2000-01-01");
    },
    maxDate() {
      return this.config.maxDate || new Date();
    }
  },
  methods: {
    onConfirm(v) {
      this.showPicker = false;
      const date = dateFormat(v, "YYYY-MM-DD");
      console.log(date);
      this.$emit("confirm", date);
    }
  }
};
</script>
